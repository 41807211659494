"use client";
import Image from "next/image";
import downloadImage from "../../../assets/page4/download.svg";
import bagTick from "../../../assets/page4/bag-tick.svg";
import profile from "../../../assets/page4/register.svg";
import person from "../../../assets/page4/person.svg";
import categoryHover from "../../../assets/page4/categoryHover.svg";
import emptywalletadd from "../../../assets/page4/empty-wallet-add.svg";
import emptywalletaddhover from "../../../assets/page4/emptywalletaddhover.svg";
import category from "../../../assets/page4/category-2.svg";
import wallet from "../../../assets/page4/wallet-add.svg";
import downloadImageHover from "../../../assets/page4/downloadHover.svg";
import bagTickHover from "../../../assets/page4/bag-tickHover.svg";
import profileHover from "../../../assets/page4/profileHover.svg";
import walletHover from "../../../assets/page4/wallet-addHover.svg";
import { useState } from "react";
import useLanguage from "@/context/useLanguage";
const Page4 = () => {
  const { language, translations } = useLanguage();
  const [isHovering, setIsHovering] = useState([false, false, false]);
  return (
    <div className="bg-[#FFF] py-32 " id="how">
      <div className="w-4/5 mx-auto ">
        {/* Header */}
        {language === "en" ? (
          <div className="flex items-center mb-16 ml-[34%]">
            <h1 className="font-ibmArabic-500 text-[4rem] w-full text-end whitespace-nowrap">
              {translations[language].section4.how}
              <span className="text-[#E9C237] font-ibmArabic-600">
                {" "}
                {translations[language].section4.start}
              </span>
            </h1>
            <div className="flex items-end justify-end w-full mt-3">
              <div className="flex w-full  h-[1px]   bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
              <div className=" flex w-full h-[1px]   bg-gradient-to-r from-[#f5de6b] to-transparent" />
            </div>
          </div>
        ) : language === "ar" ? (
          <div className="flex items-center mb-10 ">
            <div className="flex items-end justify-end w-full mt-3">
              <div className="flex w-full  h-[1px]   bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
              <div className=" flex w-full h-[1px]   bg-gradient-to-r from-[#f5de6b] to-transparent" />
            </div>
            <h1 className="font-ibmArabic-500 text-[4rem] w-full text-start whitespace-nowrap">
              {translations[language].section4.how}
              <span className="text-[#E9C237] ">
                {" "}
                {translations[language].section4.start}
              </span>
            </h1>
          </div>
        ) : (
          <div className="flex items-center mb-10 ">
            <div className="flex items-end justify-end w-full mt-3">
              <div className="flex w-full  h-[1px]   bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
              <div className=" flex w-full h-[1px]   bg-gradient-to-r from-[#f5de6b] to-transparent" />
            </div>
            <h1 className="font-ibmArabic-500 text-[4rem] w-full text-start whitespace-nowrap">
              <span className="text-[#E9C237]">
                {translations[language].section4.how}
              </span>{" "}
              {translations[language].section4.start}
            </h1>
          </div>
        )}
        {/* Content */}

        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4 place-items-center mx-auto"
          dir={language === "en" ? "ltr" : "rtl"}
        >
          {/* Download Card */}
          <div
            onMouseEnter={() => setIsHovering([true, false, false])}
            onMouseLeave={() => setIsHovering([false, false, false])}
            className={`transition-all duration-300 ease-in-out cursor-pointer flex flex-col  gap-4 p-4 bg-[#FAFAFA] hover:bg-[#FBF5DE] hover:text-[#E9C237] rounded-3xl h-[14.2rem] w-full  ${
              language === "en" ? "" : "text-right"
            }`}
          >
            <div className="w-full flex justify-end">
              <div className="bg-white p-2 rounded-xl flex">
                <Image
                  src={isHovering[0] ? profile : person}
                  alt="signupImage"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p className={`text-2xl font-ibmArabic-500`}>
                {translations[language].section4.Register}
              </p>
              <p className={`text-lg font-ibmArabic-400`}>
                {translations[language].section4.RegDesc}
              </p>
            </div>
          </div>

          {/* SignUp Card */}
          <div
            onMouseEnter={() => setIsHovering([false, true, false])}
            onMouseLeave={() => setIsHovering([false, false, false])}
            className={` transition-all duration-300 ease-in-out cursor-pointer flex flex-col  gap-4 p-4 bg-[#FAFAFA] hover:bg-[#FBF5DE] hover:text-[#E9C237] rounded-3xl h-[14.2rem] w-full ${
              language === "en" ? "" : "text-right"
            }`}
          >
            <div className="w-full flex justify-end">
              <div className="bg-white p-2 rounded-xl flex">
                <Image
                  src={isHovering[1] ? emptywalletaddhover : emptywalletadd}
                  alt="signupImage"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p className={`text-2xl font-ibmArabic-500`}>
                {translations[language].section4.DepositFunds}
              </p>
              <p className={`text-base font-ibmArabic-400`}>
                {translations[language].section4.DepositDesc}
              </p>
            </div>
          </div>

          {/* Another Card */}
          <div
            onMouseEnter={() => setIsHovering([false, false, true])}
            onMouseLeave={() => setIsHovering([false, false, false])}
            className={`transition-all duration-300 ease-in-out cursor-pointer flex flex-col  gap-4 p-4 bg-[#FAFAFA] hover:bg-[#FBF5DE] hover:text-[#E9C237] rounded-3xl  h-[14.2rem] w-full  ${
              language === "en" ? "" : "text-right"
            }`}
          >
            <div className="w-full flex justify-end">
              <div className="bg-white p-2 rounded-xl flex">
                <Image
                  src={isHovering[2] ? categoryHover : category}
                  alt="signupImage"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p className={`text-2xl font-ibmArabic-500`}>
                {translations[language].section4.ManageGold}
              </p>
              <p className={`text-base  font-ibmArabic-400`}>
                {translations[language].section4.ManageGoldDesc}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page4;
