"use client";
import Image from "next/image";
import ShariahCompliantStamp from "../../../assets/Shariah-Compliant-Stamp.svg";
import central from "../../../assets/audit.jpg";
import assay from "../../../assets/assay.svg";
import dar from "../../../assets/dar.svg";
import AAOIFI from "../../../assets/AAOIFI.svg";
import useLanguage from "@/context/useLanguage";

const MobilePageNew = () => {
  const { language, translations } = useLanguage();
  const handleImageClick = () => {
    window.open(
      "https://sabika.s3.eu-central-1.amazonaws.com/licenses/1/1732715241408-7958510.pdf",
      "_blank"
    );
  };
  return (
    <div className="bg-[#FCFCFC] py-32" id="features">
      <div className="w-4/5 mx-auto">
        {/* Header */}
        <div className="flex items-center justify-center mb-20 text-center">
          {/* Left Line */}
          {(language === "ar" || language === "eg") && (
            <div className="flex items-center justify-end w-[5rem]">
              <div className="h-[1px] w-full bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
            </div>
          )}
          {/* Header Text */}
          <h1
            className={`${
              language === "en"
                ? "font-ibm-500 mx-5"
                : "font-ibmArabic-600 mx-5"
            } text-4xl`}
          >
            {translations[language].section2.Trusted}
            <span
              className={`${
                language === "en" ? "font-ibm-600" : "font-ibmArabic-600"
              } text-[#E9C237]`}
            >
              {" "}
              {translations[language].section2.by}
            </span>
          </h1>
          {/* Right Line */}
          {language === "en" && (
            <div className="flex items-center justify-end w-[5rem]">
              <div className="h-[1px] w-full bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
            </div>
          )}
        </div>
        {/* Content */}
        <div
          className="flex justify-around items-center overflow-x-auto md:flex-row flex-nowrap gap-8 w-full px-5"
          dir={language === "en" ? "ltr" : "rtl"}
        >
          <Image
            src={AAOIFI}
            alt="AAOIFI"
            loading="eager"
            className="cursor-pointer flex-shrink-0"
          />
          <Image
            src={assay}
            alt="assay"
            loading="eager"
            className="cursor-pointer flex-shrink-0"
          />
          <Image
            src={central}
            alt="central"
            loading="eager"
            className="cursor-pointer flex-shrink-0 w-32 h-32"
          />
          <Image
            src={ShariahCompliantStamp}
            alt="Shariah-Compliant-Stamp"
            loading="eager"
            className="cursor-pointer flex-shrink-0"
            onClick={handleImageClick} // Click handler for opening the PDF
          />
        </div>
      </div>
    </div>
  );
};

export default MobilePageNew;
