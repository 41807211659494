"use client";
import Image from "next/image";
import logo from "../../../assets/SabikaLogo.svg";
import HoverText from "./HoverText";
import useLanguage from "@/context/useLanguage";
import { token } from "@/config/config";
import { useRouter } from "next/navigation";
const Navbar = () => {
  const { language, translations } = useLanguage();
  const router = useRouter();

  const handleRedirect = () => {
    router.push("https://web.sabika.app/auth");
  };
  const navData = [
    {
      onClick: () => {},
      id: 1,
      name: translations[language].navbar.home,
      url: token ? "/dashboard/" : "#home",
    },
    {
      onClick: () => {},
      id: 2,
      name: translations[language].navbar.Calculator,
      url: "#Calculator",
    },
    {
      onClick: () => {},
      id: 3,
      name: translations[language].navbar.features,
      url: "#features",
    },
    {
      onClick: () => {},
      id: 4,
      name: translations[language].navbar.howitworks,
      url: "#how",
    },
    {
      onClick: () => {},
      id: 5,
      name: translations[language].navbar.download,
      url: "/app",
    },
    {
      onClick: () => {
        handleRedirect();
      },
      id: 6,
      name: translations[language].navbar.login,
      url: "/auth",
    },
  ];

  return (
    <div
      className={`flex ${
        language === "en" ? "" : "flex-row-reverse"
      } items-center justify-around w-full pt-7 mx-auto md:w-[95%] lg:w-[95%] md:px-[5%] lg:px-[5%]`}
    >
      <div
        className={`${
          language === "en" ? "" : "justify-end"
        } w-full flex items-center`}
      >
        <Image
          src={logo}
          alt="logo"
          width={96} // Set a fixed width
          height={50} // Set a fixed height
          className="flex-shrink-0" // Prevent shrinking
          loading="eager"
        />
      </div>

      <div
        className={`flex ${
          language === "en"
            ? "font-ibm-400"
            : "flex-row-reverse font-ibmArabic-400 text-base text-[#121212]"
        } items-center w-full gap-6 `}
      >
        {navData?.map((item) =>
          item.id === 5 ? (
            <a
              onClick={item.onClick}
              key={item.id}
              href={item.url}
              className={`
                ${token?.length > 0 ? "hidden" : "block"}
                     
              
                border whitespace-nowrap font-ibmArabic-400  text-white bg-[#E9C237] px-4 py-2 rounded-xl hover:text-[#E9C237]   hover:border-[#E9C237] hover:bg-white `}
            >
              {item.name}
            </a>
          ) : (
            <a
              onClick={item.onClick}
              key={item.id}
              href={item.url}
              className={` whitespace-nowrap font-ibmArabic-400 `}
            >
              {item.name}
            </a>
          )
        )}

        <HoverText />
      </div>
    </div>
  );
};

export default Navbar;
