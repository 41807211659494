"use client";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import facebook from "@/assets/footer/facebook.svg";
import instagram from "@/assets/footer/instagram.svg";
import tiktok from "@/assets/footer/tiktok.svg";
import youtube from "@/assets/footer/youtube.svg";
import twitter from "@/assets/footer/twitter.svg";
import useLanguage from "@/context/useLanguage";

const Footer = () => {
  const { language, translations } = useLanguage();
  const handletiktopClick = () => {
    window.open("https://www.tiktok.com/@sabika_app");
  };
  const handleFaceBookClick = () => {
    window.open("https://www.facebook.com/sabika.eg");
  };
  const handleinstagramClick = () => {
    window.open("https://www.instagram.com/sabika.eg/");
  };
  const handleyoutubeClick = () => {
    window.open("https://www.youtube.com/@Sabikagold");
  };
  const handlexClick = () => {
    window.open("https://x.com/sabika_eg");
  };
  return (
    <div className="bg-[#121212] py-6">
      <div
        className={`flex flex-col md:flex-row items-center justify-between w-4/5 mx-auto`}
        dir={language === "en" ? "ltr" : "rtl"}
      >
        {/* Links section */}
        <div className="flex flex-col md:flex-row items-start gap-5 text-white w-full md:w-auto mb-4 md:mb-0">
          <Link href={"/AboutUs"} className="text-base font-ibmArabic">
            {translations[language].footer.AboutUs}
          </Link>
          <Link href={"/privacyandpolicy"} className="text-base font-ibmArabic">
            {translations[language].footer.privacy}
          </Link>
          <Link
            href={"/termsandconditions"}
            className="text-base font-ibmArabic"
          >
            {translations[language].footer.terms}
          </Link>
          <Link href={"/faq"} className="text-base font-ibmArabic">
            {translations[language].footer.faq}
          </Link>
          <Link href={"/Contactus"} className="text-base font-ibmArabic">
            {translations[language].footer.Contactus}
          </Link>
        </div>

        {/* Social Icons section */}
        <div className="flex justify-center gap-6 mt-4 md:mt-0">
          <Image
            onClick={handleFaceBookClick}
            className="cursor-pointer"
            src={facebook}
            alt="Facebook"
            width={24}
            height={24}
            loading="eager"
          />
          <Image
            onClick={handlexClick}
            className="cursor-pointer"
            src={twitter}
            alt="Twitter"
            width={24}
            height={24}
            loading="eager"
          />
          <Image
            onClick={handleinstagramClick}
            className="cursor-pointer"
            src={instagram}
            alt="Instagram"
            width={24}
            height={24}
            loading="eager"
          />
          <Image
            onClick={handletiktopClick}
            className="cursor-pointer"
            src={tiktok}
            alt="TikTok"
            width={24}
            height={24}
            loading="eager"
          />
          <Image
            onClick={handleyoutubeClick}
            className="cursor-pointer"
            src={youtube}
            alt="YouTube"
            width={24}
            height={24}
            loading="eager"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
