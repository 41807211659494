"use client";
import React from "react";
import googlePlayMobile from "../../../assets/page5/googlePlayMobile.svg";
import appStoreMobile from "../../../assets/page5/appStoreMobile.svg";
import qrCodeMobile from "../../../assets/page5/qrCodeMobile.svg";
import Image from "next/image";
import Link from "next/link";
import useLanguage from "@/context/useLanguage";
import bgImage2 from "../../../assets/Mockup/section-4-Final.png";
import appStore from "../../../assets/appstore.svg";
import amazon from "../../../assets/amazon.svg";
import android from "../../../assets/GooglePlay.svg";

const Page5Mobile = () => {
  const { language, translations } = useLanguage();
  const handleAmazonClick = () => {
    window.open(
      "https://www.amazon.com/Sabika/dp/B0DPCSVN4J/ref=sr_1_1?crid=U7K77NJQB19B&dib=eyJ2IjoiMSJ9.SezMX_pouNba3YikJlOQ1RPe-eD_UgI7adL8CkD2GryqjPo0udk4Cc7mn88PPJzOpn4HCKWnfnTRpdUDzTM-0A.bHnSpuf6tQhvtm_boArEG47kPhSj61obwhmUYGCJTNA&dib_tag=se&keywords=Sabika+app&qid=1734221302&sprefix=sabila+a%2Caps%2C604&sr=8-1",
      "_blank"
    );
  };
  const handleAppStoreClick = () => {
    window.open("https://apps.apple.com/eg/app/sabika-سبيكة/id6501984640");
  };
  const handlePlayStoreClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.sabika.app&hl=en"
    );
  };
  return (
    <div className="mt-16 mx-6" id="download">
      <div className="mx-auto ">
        {/* Header */}
        {language === "en" ? (
          <div className="flex items-center ">
            <div className="flex items-end justify-end w-[50%] mt-3">
              <div className="flex w-full  h-[1px]   bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
              <div className=" flex w-full h-[1px]   bg-gradient-to-r from-[#f5de6b] to-transparent" />
            </div>
            <h1 className="font-ibmArabic-500 text-4xl w-full text-start">
              <span className="text-[#E9C237] font-ibmArabic-600">
                {translations[language].section3.More}{" "}
              </span>
              {translations[language].section3.Coming}
            </h1>
          </div>
        ) : language === "ar" ? (
          <div className="flex items-center mb-10  ">
            <h1 className="font-ibmArabic-500 text-4xl w-full text-end">
              {translations[language].section3.More}
              <span className="text-[#E9C237] font-ibmArabic">
                {" "}
                {translations[language].section3.Coming}{" "}
              </span>
            </h1>
            <div className="flex items-end justify-end w-[50%] mt-3">
              <div className="flex w-full  h-[1px]   bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
              <div className=" flex w-full h-[1px]   bg-gradient-to-r from-[#f5de6b] to-transparent" />
            </div>
          </div>
        ) : (
          <div className="flex items-center   ">
            <h1 className="font-ibmArabic-400 text-4xl w-full text-end">
              {translations[language].section3.More}
              <span className="text-[#E9C237] font-ibmArabic-400">
                {" "}
                {translations[language].section3.Coming}{" "}
              </span>
            </h1>
            <div className="flex items-end justify-end w-[50%] mt-3">
              <div className="flex w-full  h-[1px]   bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
              <div className=" flex w-full h-[1px]   bg-gradient-to-r from-[#f5de6b] to-transparent" />
            </div>
          </div>
        )}
        {/* Content */}
        <div className="grid mx-auto " dir={language === "en" ? "ltr" : "rtl"}>
          {/* Text */}
          {language === "en" ? (
            <>
              <div className="flex flex-col items-start gap-[4.13rem]  text-4xl font-ibm-400 text-[#4C4C4C] mt-8">
                <div>
                  <p className="">
                    {translations[language].section2.StayTunedfor}{" "}
                    <span className="font-ibmArabic-600 text-[#e9c237]">
                      {" "}
                      {translations[language].section2.MoreWays}{" "}
                    </span>
                    {translations[language].section2.to}{" "}
                    <span className="font-ibmArabic-600 text-[#e9c237]">
                      {" "}
                      {translations[language].section2.ManageGold}{" "}
                    </span>
                  </p>

                  <p className="text-base text-[#333333] mt-8">
                    <span className="font-ibmArabic-600 text-[#e9c237]">
                      Sabika{" "}
                    </span>
                    is now available on the{" "}
                    <span className="font-ibmArabic-600 text-[#e9c237]">
                      Apple App Store (iOS), Google Play (Android), the web, and
                      the Amazon Appstore,
                    </span>{" "}
                    offering you a seamless and secure experience to buy, sell,
                    and manage your{" "}
                    <span className="font-ibmArabic-600 text-[#e9c237]">
                      gold fractions.
                    </span>{" "}
                    <br />
                    <br />
                  </p>
                  <div className="flex flex-row items-center gap-3 mt-2">
                    <Image
                      onClick={handleAppStoreClick}
                      src={appStore}
                      alt="appStore"
                      loading="eager"
                      className="cursor-pointer"
                    />
                    <Image
                      onClick={handlePlayStoreClick}
                      src={android}
                      alt="android"
                      loading="eager"
                      className="cursor-pointer"
                      width={135}
                    />

                    {/* <Image
                      onClick={handlePlayStoreClick}
                      src={android}
                      alt="android"
                      loading="eager"
                      className="cursor-pointer"
                    /> */}
                  </div>
                  {/* <p className="font-ibmArabic-400 text-[#4C4C4C] mt-8 text-base">
                    Download the Android app (APK file) directly from the
                    following link :
                  </p> */}
                  <Image
                    onClick={handleAmazonClick}
                    src={amazon}
                    alt="amazon"
                    loading="eager"
                    className="cursor-pointer mt-6"
                  />
                </div>
              </div>
            </>
          ) : language === "ar" ? (
            <>
              {" "}
              <div className="flex flex-col items-start gap-[4.4rem] mt-10 text-5xl font-ibmArabic  font-normal leading-[4rem]">
                <div>
                  <p className="">
                    {translations[language].section2.StayTunedfor}{" "}
                    <p>
                      اكتشف طرقًا أكثر{" "}
                      <span className="text-[#e9c237]"> ذكاءً </span>
                      لإدارة <span className="text-[#e9c237]">الذهب!</span>
                    </p>
                  </p>
                  <p className="text-lg mt-8">
                    سبيكة متاحة الآن على متجر أبل للتطبيقات (iOS)، و متجر جوجل
                    للتطبيقات (Android)، وعلى الويب، وعلى متجر أمازون للتطبيقات،
                    مما يوفر لك تجربة سلسة وآمنة لشراء، بيع، وإدارة الذهب الخاص
                    بك.
                    <br />
                    <br />
                  </p>
                  <div className="flex flex-row items-center gap-3 mt-2">
                    <Image
                      onClick={handleAppStoreClick}
                      src={appStore}
                      alt="appStore"
                      loading="eager"
                      className="cursor-pointer"
                    />
                    <Image
                      onClick={handlePlayStoreClick}
                      src={android}
                      alt="android"
                      loading="eager"
                      className="cursor-pointer"
                      width={135}
                    />
                  </div>
                  {/* <p className="font-ibmArabic-400 text-[#4C4C4C] mt-8 text-base">
                    قم بتحميل تطبيق الأندرويد (ملف APK) مباشرة من الرابط التالي
                    :
                  </p> */}
                  <Image
                    onClick={handleAmazonClick}
                    src={amazon}
                    alt="amazon"
                    loading="eager"
                    className="cursor-pointer mt-6"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="flex flex-col items-start gap-[4.4rem] mt-10 text-5xl font-ibmArabic font-normal leading-[3rem]">
                <div>
                  <p className="">
                    {translations[language].section2.StayTunedfor}{" "}
                    <span className="font-ibmArabic-600 text-[#e9c237]">
                      {" "}
                      ترقب{" "}
                    </span>
                    المزيد من الطرق{" "}
                    <span className="font-ibmArabic-600 text-[#e9c237]">
                      {" "}
                      لإدارة الذهب!{" "}
                    </span>
                  </p>
                  <p className="text-2xl mt-8">
                    تجربة{" "}
                    <span className="font-ibmArabic text-[#e9c237]">سبيكة</span>{" "}
                    السهلة عبر الإنترنت ستصبح أكثر تكاملاً قريبًا مع
                    <span className="font-ibmArabic text-[#e9c237]">
                      {" "}
                      تطبيق الهاتف.
                    </span>{" "}
                    حتى يتم إطلاقه، استمتع بموقعنا الآمن وكامل ميزاته
                    <span className="font-ibmArabic text-[#e9c237]">
                      {" "}
                      للتحكم في استثماراتك الذهبية.
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}
          {/* Image */}
          <div
            className={`flex mt-8 items-center justify-center ${
              language === "en" ? "ml-auto" : "mr-auto"
            }`}
          >
            <Image src={bgImage2} alt="bgImage2" loading="eager" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page5Mobile;
