import Image from "next/image";
import React, { useState } from "react";
import profile from "../../../assets/page4/register.svg";
import person from "../../../assets/page4/person.svg";
import categoryHover from "../../../assets/page4/categoryHover.svg";
import emptywalletadd from "../../../assets/page4/empty-wallet-add.svg";
import emptywalletaddhover from "../../../assets/page4/emptywalletaddhover.svg";
import category from "../../../assets/page4/category-2.svg";
import useLanguage from "@/context/useLanguage";

const Page4Mobile = () => {
  const { language, translations } = useLanguage();
  const [isHovering, setIsHovering] = useState([false, false, false]);

  return (
    <div className="mt-32 mx-6" id="how">
      <div className="flex items-end justify-center ">
        {" "}
        <span
          className={`flex w-full   h-[1px]  bg-gradient-custom mb-3 ${
            language === "en" ? "hidden" : ""
          }`}
        ></span>
        <p
          className={`${
            language === "en" ? "ml-10" : "mr-16"
          } text-4xl font-ibmArabic-500 whitespace-nowrap`}
        >
          {translations[language].section4.how}{" "}
          <span className="text-[#E9C237]">
            {translations[language].section4.start}{" "}
          </span>
        </p>
        <span
          className={`flex w-full   h-[1px]  bg-gradient-custom mb-3 ${
            language === "en" ? "" : "hidden"
          }`}
        ></span>
      </div>
      <div className="flex flex-col items-center gap-5 mt-16">
        {/* Download Card */}

        <div
          onMouseEnter={() => setIsHovering([true, false, false])}
          onMouseLeave={() => setIsHovering([false, false, false])}
          className={`transition-all duration-300 ease-in-out cursor-pointer flex flex-col items-center gap-4 p-3 bg-[#FAFAFA] hover:bg-[#FBF5DE] hover:text-[#E9C237] rounded-3xl h-[14.2rem] w-full max-w-[23.5rem] ${
            language === "en" ? "" : "text-right"
          }`}
        >
          <div className="w-full flex justify-end">
            <div className="bg-white p-2 rounded-xl flex">
              <Image src={isHovering[0] ? profile : person} alt="signupImage" />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p
              className={`text-2xl ${
                language === "en" ? "font-ibmArabic-500" : "font-ibmArabic-500"
              }`}
            >
              {translations[language].section4.Register}
            </p>
            <p
              className={`text-lg ${
                language === "en" ? "font-ibmArabic-400" : "font-ibmArabic-400"
              }`}
            >
              {translations[language].section4.RegDesc}
            </p>
          </div>
        </div>

        {/* SignUp Card */}
        <div
          onMouseEnter={() => setIsHovering([false, true, false])}
          onMouseLeave={() => setIsHovering([false, false, false])}
          className={` transition-all duration-300 ease-in-out cursor-pointer flex flex-col items-center gap-4 p-6 bg-[#FAFAFA] hover:bg-[#FBF5DE] hover:text-[#E9C237] rounded-3xl h-[14.2rem] w-full max-w-[23.5rem] ${
            language === "en" ? "" : "text-right"
          }`}
        >
          <div className="w-full flex justify-end">
            <div className="bg-white p-2 rounded-xl flex">
              <Image
                src={isHovering[1] ? emptywalletaddhover : emptywalletadd}
                alt="signupImage"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p className={`text-2xl font-ibmArabic-500`}>
              {translations[language].section4.DepositFunds}
            </p>
            <p className={`text-base font-ibmArabic-400`}>
              {translations[language].section4.DepositDesc}
            </p>
          </div>
        </div>

        {/* Another Card */}
        <div
          onMouseEnter={() => setIsHovering([false, false, true])}
          onMouseLeave={() => setIsHovering([false, false, false])}
          className={`transition-all duration-300 ease-in-out cursor-pointer flex flex-col items-center gap-4 p-6 bg-[#FAFAFA] hover:bg-[#FBF5DE] hover:text-[#E9C237] rounded-3xl h-[14.2rem] w-full max-w-[23.5rem] ${
            language === "en" ? "" : "text-right"
          }`}
        >
          <div className="w-full flex justify-end">
            <div className="bg-white p-2 rounded-xl flex">
              <Image
                src={isHovering[2] ? categoryHover : category}
                alt="signupImage"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p className={`text-2xl font-ibmArabic-500`}>
              {translations[language].section4.ManageGold}
            </p>
            <p className={`text-base  font-ibmArabic-400`}>
              {translations[language].section4.ManageGoldDesc}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page4Mobile;
