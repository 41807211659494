import Image from "next/image";
import facebook from "@/assets/footer/facebook.svg";
import instagram from "@/assets/footer/instagram.svg";
import tiktok from "@/assets/footer/tiktok.svg";
import youtube from "@/assets/footer/youtube.svg";
import twitter from "@/assets/footer/twitter.svg";
import useLanguage from "@/context/useLanguage";
import Link from "next/link";

const FooterMobile = () => {
  const { language, translations } = useLanguage();
  const handletiktopClick = () => {
    window.open("https://www.tiktok.com/@sabika_app");
  };
  const handleFaceBookClick = () => {
    window.open("https://www.facebook.com/sabika.eg");
  };
  const handleinstagramClick = () => {
    window.open("https://www.instagram.com/sabika.eg/");
  };
  const handleyoutubeClick = () => {
    window.open("https://www.youtube.com/@Sabikagold");
  };
  const handlexClick = () => {
    window.open("https://x.com/sabika_eg");
  };
  return (
    <div className="bg-[#121212] text-white py-12   mt-[7.375rem] ">
      <div
        className={`${
          language == "en"
            ? "font-ibmArabic-400"
            : "text-right font-ibmArabic-400 mr-3"
        } flex flex-col gap-5 text-base px-6`}
      >
        <Link
          href={"/AboutUs"}
          className={`text-base ${
            language === "en" ? "font-ibmArabic-400" : "font-ibmArabic-400"
          }`}
        >
          {" "}
          {translations[language].footer.AboutUs}
        </Link>
        <Link
          href={"/privacyandpolicy"}
          className={`text-base ${
            language === "en" ? "font-ibmArabic-400" : "font-ibmArabic-400"
          }`}
        >
          {" "}
          {translations[language].footer.privacy}
        </Link>

        <Link
          href={"/termsandconditions"}
          className={`text-base ${
            language === "en" ? "font-ibmArabic-400" : "font-ibmArabic-400"
          }`}
        >
          {translations[language].footer.terms}
        </Link>
        <Link
          href={"/faq"}
          className={`text-base ${
            language === "en" ? "font-ibmArabic-400" : "font-ibmArabic-400"
          }`}
        >
          {" "}
          {translations[language].footer.faq}
        </Link>
        <Link
          href={"/Contactus"}
          className={`text-base ${
            language === "en" ? "font-ibmArabic-400" : "font-ibmArabic-400"
          }`}
        >
          {" "}
          {translations[language].footer.Contactus}
        </Link>
        {/* <p>{translations[language].footer.privacy}</p>
        <p>{translations[language].footer.terms}</p> */}
      </div>
      <div
        className={`flex justify-around mt-16 w-[75%] mx-6 ${
          language === "en" ? "" : "flex-row-reverse  ml-auto"
        }`}
      >
        <Image
          onClick={handleFaceBookClick}
          className="cursor-pointer"
          src={facebook}
          alt=""
          loading="eager"
        />
        <Image
          onClick={handlexClick}
          className="cursor-pointer"
          src={twitter}
          alt=""
          loading="eager"
        />
        <Image
          onClick={handleinstagramClick}
          className="cursor-pointer"
          src={instagram}
          alt=""
          loading="eager"
        />
        <Image
          onClick={handletiktopClick}
          className="cursor-pointer"
          src={tiktok}
          alt=""
          loading="eager"
        />
        <Image
          onClick={handleyoutubeClick}
          className="cursor-pointer"
          src={youtube}
          alt=""
          loading="eager"
        />
      </div>
    </div>
  );
};

export default FooterMobile;
